var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[(_vm.isFirtsMatterOfLevel)?[_c('td',{staticClass:"td-plan-level",attrs:{"rowspan":_vm.cycleMattersAmount}},[_vm._v(" "+_vm._s(_vm.matter.plan_level)+" ")])]:_vm._e(),(false)?_c('td',[_vm._v(_vm._s(_vm.matter.plan_level))]):_vm._e(),(false)?_c('td',[_vm._v(_vm._s(_vm.getFormationArea(_vm.matter.formation_area)))]):_vm._e(),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:(
      `${_vm.$getVisibleNames(
        'mesh.formationarea',
        false,
        'Área De Formación'
      )}: ${_vm.getFormationArea(_vm.matter.formation_area)}`
    ),expression:"\n      `${$getVisibleNames(\n        'mesh.formationarea',\n        false,\n        'Área De Formación'\n      )}: ${getFormationArea(matter.formation_area)}`\n    ",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}],staticClass:"td-matter"},[_c('b-link',{attrs:{"to":{
        name: 'MatterProgramView',
        params: { matter_id: _vm.matter.id },
      }}},[_vm._v(" "+_vm._s(_vm.matter.code)+" - "+_vm._s(_vm.getMatterName(_vm.matter.matter).toUpperCase())+" ")])],1),_c('td',[_c('div',{staticClass:"cognitive-color-container"},[(_vm.studyUnitsNoCognitiveLevel().length > 0)?_c('div',{staticClass:"cognitive-color",style:({
          'background-color': '#ebebeb !important',
        }),attrs:{"id":'study_units-' + _vm.matter.id + '- 0'}},[_c('strong',{staticStyle:{"vertical-align":"-webkit-baseline-middle"}},[_vm._v(" "+_vm._s(_vm.studyUnitsNoCognitiveLevel().length)+" ")]),_c('b-popover',{attrs:{"target":'study_units-' + _vm.matter.id + '- 0',"placement":"left","triggers":"hover focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.ramicro", false, "RA Micro"))+" sin "+_vm._s(_vm.$getVisibleNames( "mesh.cognitivelevel", false, "Nivel Cognitivo" ))+" ")]},proxy:true}],null,false,3808551845)},[_c('div',{staticStyle:{"overflow":"auto","max-height":"350px"}},_vm._l((_vm.studyUnitsNoCognitiveLevel()),function(item){return _c('div',{key:'study-unit:' + item.id + 'cl:' + '0' + 'matter:' + _vm.matter.id},[_c('SentenceContainer',{attrs:{"unique_id":_vm.matter.id + '-' + item.id + '-' + item.full_sentence,"Sentence":item}})],1)}),0)])],1):_vm._e(),_vm._l((_vm.CognitiveLevelFilter),function(cognitive_level){return _c('div',{key:cognitive_level.id,staticClass:"cognitive-color",style:({
          'background-color':
            cognitive_level.color == '' ||
            _vm.$equals(cognitive_level.color, '#FFFFFF')
              ? '#ebebeb'
              : cognitive_level.color + ' !important',
          color: _vm.$lightOrDark(
            cognitive_level.color == '' ||
              _vm.$equals(cognitive_level.color, '#FFFFFF')
              ? '#ebebeb'
              : cognitive_level.color
          )
            ? '#2b2a2a !important'
            : 'white !important',
        }),attrs:{"id":'study_units-' + _vm.matter.id + '-' + cognitive_level.id}},[_c('strong',{staticStyle:{"vertical-align":"-webkit-baseline-middle"}},[_vm._v(" "+_vm._s(_vm.studyUnitsCount(cognitive_level).length)+" ")]),_c('b-popover',{attrs:{"target":'study_units-' + _vm.matter.id + '-' + cognitive_level.id,"placement":"left","triggers":"hover focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.ramicro", false, "RA Micro"))+" con "+_vm._s(_vm.$getVisibleNames( "mesh.cognitivelevel", false, "Nivel Cognitivo" ))+": "+_vm._s(cognitive_level.level)+" ")]},proxy:true}],null,true)},[_c('div',{staticStyle:{"overflow":"auto","max-height":"350px"}},_vm._l((_vm.studyUnitsCount(cognitive_level)),function(item){return _c('div',{key:'study-unit:' +
                item.id +
                'cl:' +
                cognitive_level.id +
                'matter:' +
                _vm.matter.id},[_c('SentenceContainer',{attrs:{"unique_id":_vm.matter.id +
                  '-' +
                  item.id +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  item.full_sentence,"Sentence":item}})],1)}),0)])],1)})],2)]),_vm._l((_vm.competences),function(competence){return [(_vm.getCheckCompetence(_vm.matter, competence.competence))?_c('td',{key:_vm.matter.id + 'c' + competence.id,staticClass:"check-competence",style:({
        'background-color': _vm.cognitiveLeveLColor(competence) + ' !important',
      }),attrs:{"id":_vm.matter.id + 'c' + competence.id}},[_c('b-popover',{attrs:{"target":_vm.matter.id + 'c' + competence.id,"placement":"left","triggers":"hover focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [(
              !(
                (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                _vm.$debug_change_duoc
              )
            )?[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+": "+_vm._s(_vm.getCycles(competence.cycle))+" ")]:_vm._e(),[(competence.verb == null)?_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" sin "+_vm._s(_vm.$getVisibleNames( "mesh.cognitivelevel", false, "Nivel Cognitivo" ))+" "),(_vm.cognitiveLeveLColor(competence) != '#ebebeb')?_c('span',[_vm._v(" seleccionado ")]):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" con "+_vm._s(_vm.$getVisibleNames( "mesh.cognitivelevel", false, "Nivel Cognitivo" ))+": "),_c('strong',[_vm._v(" "+_vm._s(_vm.getCognitiveLevel(competence.verb))+" ")])])]]},proxy:true}],null,true)},[_c('div',{staticClass:"competence-div"},[_vm._v(" "+_vm._s(competence.profile_cycle ? competence.order + "." + competence.profile_cycle.order : competence.order + ".")+" "),_c('SentenceContainer',{attrs:{"unique_id":_vm.matter.id +
              '-' +
              competence.id +
              '-' +
              competence.full_sentence +
              '-' +
              competence.order,"Sentence":competence}})],1)]),_c('b-icon',{style:({
          color: _vm.$lightOrDark(_vm.cognitiveLeveLColor(competence))
            ? '2b2a2a !important'
            : '#white !important',
        }),attrs:{"icon":"check","width":"25","height":"25"}})],1):_c('td',{key:_vm.matter.id + 'c' + competence.id})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }